import axios from '../uitls/require';

export default {
    /**
     * banner列表
     * @param {*} params 入参
     * @return {*}
     */
    bannerList(params) {
        return axios.post('/admin/banner/list', params);
    },
    /**
     * 更改状态
     * @param {*} params 入参
     * @return {*}
     */
    changeStatus(params) {
        return axios.post('/admin/banner/change-status', params);
    },
    /**
     * banner编辑/新增
     * @param {*} params 入参
     * @return {*}
     */
    bannerSave(params) {
        return axios.post('/admin/banner/save', params);
    },
    /**
     * banner详情
     * @param {*} params 入参
     * @return {*}
     */
    bannerDetail(params = {}) {
        return axios.get('/admin/banner/detail', { params });
    }
};
