<template>
    <div v-if="hasResource('banner_management')" class="aops-banner">
        <div class="search-form">
            <el-button
                v-if="hasResource('banner_add')"
                class="black-btn btn-l"
                @click="handleAdd"
            >新增</el-button>
            <SearchForm :form="form" @onSearch="handleSearch" @onReset="handleReset" />
        </div>
        <el-table
            v-loading="tableLoading"
            :data="listData"
            style="width: 100%;"
            header-cell-class-name="table-head"
            cell-class-name="table-cell"
            class="data-table"
        >
            <el-table-column
                v-for="col in column"
                :key="col.prop"
                :label="col.label"
                :prop="col.prop"
                :width="col.width"
                :min-width="col.minWidth"
                :align="col.align"
                :show-overflow-tooltip="col.showOverflowTooltip"
                :fixed="col.fixed"
            >
                <template slot-scope="{row}">
                    <div v-if="col.prop === 'banner'">
                        <img v-if="row.banner.image_url" class="aop-banner-img" :src="row.banner.image_url" alt=""  />
                    </div>
                    <!-- 状态 -->
                    <span v-else-if="col.prop === 'status'">
                        {{row.status | statusFilter}}
                    </span>
                    <span v-else-if="col.prop === 'publish_time'">
                        {{row[col.prop] | dateFilter}}
                    </span>
                    <div v-else-if="col.prop === 'operation'">
                        <template v-for="item in getOperaBtnData(row.status)">
                            <el-button
                                v-if="!item.resource || hasResource(item.resource)"
                                :key="item.type"
                                size="mini"
                                type="text"
                                @click="handleOperation(item, row)"
                            >
                                {{item.text}}
                            </el-button>
                        </template>
                    </div>
                    <span v-else>{{row[col.prop] || '--'}}</span>
                </template>
            </el-table-column>
        </el-table>
        <div class="ta-r mg-t-18">
            <el-pagination
                :page-sizes="[10,20,50]"
                :page-size="pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                :current-page.sync="currentPage"
                @size-change="handleSizeChange"
                @current-change="handleSearch"
            ></el-pagination>
        </div>
        <statusDialog
            :dialog-visible.sync="statusDialogVisible"
            :banner="curBanner"
            @onHandleQuery="handleSearch"
        />
    </div>
</template>

<script>
import { formatDate } from 'ecoplants-lib';
import Api from '@/api/banner';
import SearchForm from './search/search.vue';
import statusDialog from './statusDialog/statusDialog.vue';
import { initForm, column, statusOption, operationBtn } from './data';

export default {
    components: { SearchForm, statusDialog },
    filters: {
        /**
         * 表格状态格式化
         * @param {number} val 状态
         * @returns {string | number}
        */
        statusFilter(val) {
            const child = statusOption.find((item) => item.value === `${val}`);
            return child ? child.text : val;
        },
        /**
         * 时间格式化
         * @param {number} val 时间戳
         * @param {number} status 状态
         * @returns {string}
         */
        dateFilter(val) {
            return val ? formatDate(val * 1000, 'yyyy-MM-dd hh:mm:ss') : '--';
        }
    },
    /**
     * data
     * @returns {*}
     */
    data() {
        return {
            form: { ...initForm }, // 查询表单
            column, // 表格的列
            listData: [],
            tableLoading: '',
            pagesize: 10,
            total: 0,
            currentPage: 1,
            curBanner: {},
            statusDialogVisible: false // 停用/启用弹窗
        };
    },
    /**
     * mounted
     */
    mounted() {
        this.getList();
    },
    methods: {
        /**
         * 获取表格数据
         */
        getList() {
            this.tableLoading = true;
            const data = {
                limit: this.pagesize,
                page: this.currentPage,
                filter: {
                    ...this.form
                }
            };
            Api.bannerList(data).then((res) => {
                if (res.data.code === 10200) {
                    this.listData = res.data.data.items;
                    this.total = res.data.data.total;
                } else {
                    this.$$error(res.data.message);
                }
                this.tableLoading = false;
            }).catch(() => {
                this.tableLoading = false;
            });
        },
        /**
         * 点击新增/编辑按钮
         * @param {*} query 路由参数
         */
        handleAdd(query = {}) {
            this.$router.push({
                path: '/operations/add-banner',
                ...query
            });
        },
        /**
         * 点击重置
         */
        handleReset() {
            this.form = { ...initForm };
        },
        /**
         * 分页
         * @param {number} val 一页多少条
        */
        handleSizeChange(val) {
            this.pagesize = val;
            this.getList();
        },
        /**
         * 查询
         * @param {number} val 页码
        */
        handleSearch(val) {
            this.currentPage = val;
            this.getList();
        },
        /**
         * 处理操作按钮
         * @param {Object} item 操作按钮
         * @param {Object} row 操作的积分方案
        */
        handleOperation(item, row) {
            this.curBanner = { ...row };
            switch (item.type) {
                case 'edit':
                    this.handleAdd({
                        query: {
                            id: row.id
                        }
                    });
                    break;
                case 'disable':
                    this.statusDialogVisible = true;
                    break;
                case 'able':
                    if (row.scenePack && `${row.scenePack.status}` === '2') {
                        // 点击启用按钮时，启用的banner关联的场景包已停用
                        this.$$warning('关联的场景包已停用，请先启用关联的场景包');
                        return;
                    }
                    this.statusDialogVisible = true;
                    break;
                default:
                    break;
            }
        },
        /**
         * 获取表格操作按钮
         * @param {number} status 状态
         * @returns {Array}
        */
        getOperaBtnData(status) {
            return operationBtn(status);
        }
    }
};
</script>
<style lang="less">
@import "./index.less";
</style>
