<template>
  <div class="aops-banner-search">
        <el-form ref="form" :inline="true" :model="form" label-width="100px">
            <el-form-item label="banner名称">
                <el-input
                    v-model="form.banner_name"
                    placeholder="请输入banner名称"
                ></el-input>
            </el-form-item>
            <el-form-item label="banner状态">
                <el-select v-model="form.status">
                    <el-option value="" label="全部"></el-option>
                    <el-option
                        v-for="item in statusOption"
                        :key="item.value"
                        :label="item.text"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <div class="text-btn">
            <span class="serach-button" @click="handleSearch">查询</span>
            <span class="clear-button" @click="handleReset">清空</span>
        </div>
    </div>
</template>

<script>
import { statusOption } from '../data';

export default {
    props: {
        form: {
            type: Object,
            default: () => ({})
        }
    },
    /**
     * data
     * @returns {*}
     */
    data() {
        return {
            statusOption
        };
    },
    methods: {
        /**
         * 点击查询
         */
        handleSearch() {
            this.$emit('onSearch', 1);
        },
        /**
         * 点击清空
         */
        handleReset() {
            this.$emit('onReset');
            this.$refs.form.resetFields();
        }
    }
};
</script>
<style lang="less">
@import "./search.less";
</style>
