<template>
    <!-- 停用 启用 -->
    <el-dialog
        top="12%"
        :visible="dialogVisible"
        width="458px"
        :modal-append-to-body="false"
        class="pop-dialog aops-banner-status-dialog"
        @close="hide"
    >
        <div class="aop-confirm-info">
            是否{{`${banner.status}` === '2' ? '启用' : '停用'}}{{banner.banner_name}}
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" class="confirm-button" :loading="btnLoading" @click="changeStatus" >确认</el-button>
            <el-button class="cancel-button" @click="hide">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
import Api from '@/api/banner';

export default {
    props: {
        dialogVisible: {
            type: Boolean,
            default: false
        },
        banner: {
            type: Object,
            default: () => ({})
        }
    },
    /**
     * data
     * @returns {*}
    */
    data() {
        return {
            btnLoading: false
        };
    },
    methods: {
        /**
         * 点击确定按钮
        */
        changeStatus() {
            this.btnLoading = true;
            const data = {
                banner_id: this.banner.id,
                status: this.banner.status === '2' ? 3 : 2 // 1:启用,2:停用
            };
            Api.changeStatus(data).then((res) => {
                this.handleApiRes(res);
            }).catch((error) => {
                this.$$error(error);
                this.btnLoading = false;
            });
        },
        /**
         * 处理接口返回
         * @param {Object} res 结果
        */
        handleApiRes(res) {
            if (res.data.code === 10200) {
                this.hide();
                this.$$success(res.data.message);
                this.$emit('onHandleQuery', 1);
            } else {
                this.$$error(res.data.message);
            }
            this.btnLoading = false;
        },
        /**
         * 关闭弹窗
        */
        hide() {
            this.$emit('update:dialogVisible', false);
        }
    }
};
</script>
<style lang="less">
@import "./statusDialog.less";
</style>
